import UserCache from "@hview/hview-utils/lib/cache/user";
import AppBoot from "@/tools/app-boot";

/**
 * @description 用户登录
 */
export class UserLogin {

    /**
     * @description 统一设置用户登录的数据
     * @param data
     */
    public static setData (data: any) {
        const {tenants, userinfo} = data;
        // 设置缓存数据
        UserCache.setUserInfo(userinfo);
        UserCache.setTenant(tenants);
        UserCache.setToken(userinfo.token);
        AppBoot.start();
        // 设置默认租户
        if (tenants.length > 0) {
            let first = tenants[0];
            // 获取租户
            let tenant = first.value;
            let team = first.children[0];
            UserCache.setDefaultTenant([tenant, team.value]);
        }

    }
}