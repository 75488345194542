import Http, {RequestParams} from "@hview/hview-http";

/**
 * @description 通过密码登录
 * @param params
 */
export const loginByPasswordApi = (params: RequestParams) => {
    return Http.post("/api/v2/doctor/login/login", params);
}

/**
 * @description 发送验证码
 * @param params
 */
export const sendByCaptchaApi = (params: RequestParams) => {
    return Http.post("/api/v2/doctor/login/login", params);
}

/**
 * @description 获取验证码
 */
export const getCaptchaApi = () => {
    return Http.post("/api/v2/doctor/login/captcha");
}