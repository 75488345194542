<template>
  <h-container >
    <div v-loading="loading" class="card-row m-t-30">
      <template v-if="uiModel">
        <div class="statistic-card cursor-pointer" style="background: rgba(78,136,243, 0.1)" @click="toPage('/doctor/patient')">
          <div class="title">中心总数</div>
          <div class="num">{{ uiModel.team_num }}</div>
          <div class="icon" style="color: rgba(78,136,243, 0.8); background: rgba(78,136,243, 0.2)">
            <i class="iconfont icon-shequ"></i>
          </div>
        </div>
        <div class="statistic-card" style="background: rgba(19, 206, 102, 0.1)">
          <div class="title">成员总数</div>
          <div class="num">{{ uiModel.team_member_num }}</div>
          <div class="icon" style="color: rgba(19, 206, 102, 0.8); background: rgba(19, 206, 102, 0.2)">
            <i class="iconfont icon-chengyuan"></i>
          </div>
        </div>
        <div class="statistic-card" style="background: rgba(230, 162, 60, 0.1)">
          <div class="title">7日新增</div>
          <div class="num">{{ uiModel.new_add_in_7_day }}</div>
          <div class="icon" style="color: rgba(230, 162, 60, 0.8); background: rgba(230, 162, 60, 0.2)">
            <i class="iconfont icon-rili"></i>
          </div>
        </div>
        <div class="statistic-card cursor-pointer" style="background: rgba(253, 78, 78, 0.1)" @click="toPage('/doctor/chat')">
          <div class="title">未读消息</div>
          <div class="num">{{ uiModel.unread_num }}</div>
          <div class="icon" style="color: rgba(253, 78, 78, 0.8); background: rgba(253, 78, 78, 0.2)">
            <i class="iconfont icon-weiduxiaoxi-copy"></i>
          </div>
        </div>
      </template>
    </div>
  </h-container>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import {useRouter} from "vue-router";
import {getDashboardApi} from "@/api/doctor";
import {useApp} from "@hview/hview-vue3/packages/hooks";
const router = useRouter();
const {loading} = useApp();
const uiModel = ref(null);
const load = () => {
  loading.value = true;
  getDashboardApi().then((res: any) => {
    uiModel.value = res.data;
  }).finally(() => {
    loading.value = false;
  });
}

const toPage = (path: string) => {
  router.push(path);
}


onMounted(() => {
  load();
});
</script>

<style lang="scss">
.card-row {
  display: flex;
  justify-content: space-between;
  .statistic-card {
    width: 260px;
    margin: 0 5px;
    position: relative;
    height: 100px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 16px;
    .title {
      color: rgb(78,89,105);
      font-size: 16px;
    }
    .num {
      color: rgb(29,33,41);
      font-size: 24px;
      margin-top: 10px;
    }
    .icon {
      position: absolute;
      right: 10px;
      top: 30px;
      text-align: center;
      line-height: 50px;
      width: 50px;
      height: 50px;
      background: #00AA96;
      border-radius: 50%;
      .iconfont {
        font-size: 30px;
      }
    }
  }
}

</style>