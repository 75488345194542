/**
 * @description el-form公共表单验证类库
 * @author zhaxianhe
 * @version v1.0
 */
import Pattern from "./pattern";

export class ElFormValidate {
    /**
     * @description 验证手机号码
     * @param rule
     * @param value
     * @param callback
     */
    public static validateMobile(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error("请输入手机号码"));
        }
        if (!Pattern.MOBILE.test(value)) {
            callback(new Error(rule.message || "请输入正确的手机号码"));
        }
        callback();
    }

    /**
     * @description 验证邮箱
     * @param rule
     * @param value
     * @param callback
     */
    public static validateEmail(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error("请输入邮箱"));
        }
        if (!Pattern.EMAIL.test(value)) {
            callback(new Error(rule.message || "请输入正确的邮箱"));
        }
        callback();
    }

}