<template>
  <h-layout>
      <micro-app v-for="(item, index) in microApps" :key="index" :name="item.name" :url="item.url" :baseroute="item.baseRoute || item.path" router-mode='native'></micro-app>
  </h-layout>
</template>

<script lang="ts" setup>
  import MicroAppCache from "@hview/hview-utils/lib/cache/micro";
  import type {MicroApp} from "@hview/hview-utils/lib/cache/micro";
  import {ref, watch, onMounted} from "vue";
  import {useRoute} from "vue-router";
  let microApps = ref<MicroApp[]>([]);
  const route = useRoute();

  watch(route, () => {
    init();
  });

  const init = () => {
    // 从缓存读取微应用配置
    const micros = MicroAppCache.getMicroApp();
    if (!micros) {
      throw new Error("未找到微应用相关配置");
    }
    // 获取应用名称
    const appName = 'doctor';
    let microConfig = micros.find(item => item.name == appName);
    if (microConfig) {
      microApps.value = [microConfig];
    } else {
      throw "未找到相关微应用配置!";
    }
  }

  onMounted(() => {
    init();
  });


</script>

<style lang="scss">
micro-app, micro-app-body {
  height: 100%;
}
</style>